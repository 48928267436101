import { useReCaptcha, VueReCaptcha } from 'vue-recaptcha-v3'

export default function useRecaptchaLoaded() {
    const recaptchaKey = useRuntimeConfig().public.recaptcha.siteKey

    useNuxtApp().vueApp.use(VueReCaptcha, {
        siteKey: recaptchaKey,
        loaderOptions: {
            autoHideBadge: true,
        },
    })

    const recaptchaInstance = useReCaptcha()

    onMounted(() => {
        if (!recaptchaInstance?.isLoaded.value) {
            recaptchaInstance?.recaptchaLoaded()
        }
    })

    function loadRecaptcha(action: string) {
        return recaptchaInstance?.executeRecaptcha(action)
    }

    return {
        loadRecaptcha,
    }
}
